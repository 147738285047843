<style>
.field p {
  margin-bottom: 0;
}

.select select {
  border: 1px solid #b5b5b5 !important;
  color: inherit !important;
  background: transparent !important;
}
.select:not(.is-multiple):not(.is-loading):hover::after {
  color: inherit !important;
}
.select:not(.is-multiple):not(.is-loading)::after {
  border-color: #ffffff !important;
  right: 1.125em;
  z-index: 1;
}
</style>
<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="card p-3" :class="darkModeCard">
          <content-loader v-if="isLoading" :width="251" :height="245" :speed="2" primaryColor="#b0b0b0" secondaryColor="#ecebeb">
            <rect x="304" y="-46" rx="3" ry="3" width="350" height="6" />
            <rect x="371" y="-45" rx="3" ry="3" width="380" height="6" />
            <rect x="484" y="-45" rx="3" ry="3" width="201" height="6" />
            <rect x="0" y="0" rx="3" ry="3" width="213" height="19" />
            <rect x="0" y="53" rx="3" ry="3" width="252" height="6" />
            <rect x="0" y="63" rx="3" ry="3" width="202" height="6" />
            <rect x="0" y="102" rx="3" ry="3" width="170" height="8" />
            <rect x="0" y="164" rx="3" ry="3" width="33" height="32" />
            <rect x="0" y="211" rx="3" ry="3" width="250" height="34" />
            <rect x="0" y="74" rx="3" ry="3" width="160" height="6" />
            <rect x="218" y="164" rx="3" ry="3" width="33" height="32" />
            <rect x="44" y="164" rx="3" ry="3" width="165" height="32" />
            <rect x="0" y="123" rx="3" ry="3" width="250" height="31" />
            <rect x="0" y="25" rx="3" ry="3" width="40" height="12" />
          </content-loader>

          <div v-else>
            <div class="row">
              <div class="col">
                <div class="row">
                  <div class="col-12 col-sm-8">
                    <h2 id="product-title" class="m-0 no-overflow">{{title}}</h2>
                  </div>
                  <div class="col-12 col-sm-4 d-sm-flex justify-content-end align-items-start">
                    <h6 id="price-range" class="m-0 mt-1 no-overflow">{{price}}</h6>
                  </div>
                </div>
              </div>
              <div class="col d-flex d-sm-none justify-content-end">
                <i id="product-added" class="fas fa-shopping-cart" style="display: block;"></i>
              </div>
            </div>
            <div class="row pt-2">
              <div id="product-description" class="multine-ellipsis">{{description}}</div>

              <label class="label mt-3" :class="darkModeText">Select an option &amp; quantity</label>
              <b-select type="is-dark" :class="darkModeText" icon-pack="fas" v-model="selected" expanded>
                <option v-for="option in options" :value="option.id" :key="option.id">{{`${title}: ${option.item_variation_data.name}`}}</option>
              </b-select>

              <b-field class="mt-3">
                <b-numberinput v-model="quantity" :min="1" :value="1" icon-pack="fas" type="is-dark"></b-numberinput>
              </b-field>

              <div class="add-to-cart mt-2">
                <button v-bind:disabled="addDisabled" v-on:click="addToCart()" id="add-to-cart" class="btn js-cd-add-to-cart p-3 w-100" style="background: #212121;">
                  <span v-if="!addDisabled" style="color:white; margin-right: 10px">Add to Cart</span>
                  <span v-else-if="fullDisable" style="color:white; margin-right: 10px">Not Available</span>
                  <span v-else style="color:white; margin-right: 10px">Please wait...</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ContentLoader } from "vue-content-loader";
import { darkModeStyle } from "@assets/js/darkMode";
import Dinero from "dinero.js";

export default {
  name: "OrderMenu",
  components: {
    ContentLoader,
  },
  data() {
    return {
      title: "No Title",
      description: "No description",
      price: "£0.00",
      quantity: 1,
      options: [],
      selected: null,
      isLoading: true,
      isAdded: false,
      category: "",
      darkModeCard: darkModeStyle("bg-dark text-light", "bg-light text-dark"),
      darkModeText: darkModeStyle("text-light", "text-dark"),
      addDisabled: true,
      fullDisable: false,
    };
  },
  methods: {
    loadItem() {
      this.isLoading = true;
      this.$store
        .dispatch("getItem", { id: this.$route.params.id })
        .then(async (item) => {
          this.isLoading = false;

          this.title = item.item_data.name;
          this.description = item.item_data.description;
          this.price = this.formatPrice(
            item.item_data.variations[0].item_variation_data.price_money.amount
          );

          item.item_data.variations.forEach((variation) => {
            this.options.push({
              id: variation.id,
              item_variation_data: variation.item_variation_data,
            });
          });

          this.selected = item.item_data.variations[0].id;

          console.log(item.item_data.category_id);
          this.$store.dispatch("getCategoryName", item.item_data.category_id)
            .then((category) => {
              this.category = category;
              if (category.length > 1) {
                this.addDisabled = false;
              } else {
                window.location.reload();
              }
            })
            .catch((error) => {
              this.fullDisable = true;
            });

        });
    },
    getSelected(newVal) {
      const current = this.options.find((option) => {
        return option.id == newVal;
      });

      return current;
    },
    formatPrice(price) {
      return Dinero({ amount: price, currency: "GBP" }).toFormat();
    },
    addToCart() {
      if (this.addDisabled == false) {
        const selected = this.getSelected(this.selected);
        this.$shoppingCart.addItemToCart(
          selected.id,
          `${this.title}: ${selected.item_variation_data.name}`,
          selected.item_variation_data.price_money.amount,
          this.quantity,
          this.category
        );

        this.quantity = 1;
      }
    },
  },
  computed: {},
  watch: {
    selected(newVal) {
      const current = this.getSelected(newVal);
      this.quantity = 1;
      this.price = this.formatPrice(
        current.item_variation_data.price_money.amount
      );
    },
    quantity(newVal) {
      const current = this.getSelected(this.selected);
      const price = current.item_variation_data.price_money.amount;
      this.price = this.formatPrice(price * newVal);
    },
  },
  mounted() {
    this.loadItem();
  },
};
</script>
